import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider, Link } from '@mui/material';
import LogoButtonWithIcon from '../LogoButtonWithIcon';
import { FaDiscord } from 'react-icons/fa';

function NAEHL_Home() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        document.title = `Chel stats | NAEHL`;
    }, []);


    const BoxStyle = {
        padding: windowWidth < 600 ? '10px 0px 10px 0px' : windowWidth < 900 ? '20px' : '20px',
    };

    const ContainerStyle = {
        padding: '0px',
    };

    const logoPath = require(`./Logos/NAEHL_Logo.png`).default;

    return (
        <Container>
            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px', marginTop: '20px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="h6" color="white" fontWeight="800" gutterBottom>
                        Welcome to the NAEHL!
                    </Typography>
                </Box>
                <Typography variant="subtitle1" color="white" fontWeight="300" gutterBottom>
                    Join our Discord server!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href="https://discord.gg/QwnaBW4M"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        marginTop: 1,
                        backgroundColor: '#5865F2', // Discord's branding color
                        '&:hover': {
                            backgroundColor: '#4e5cc8' // Slightly darker shade for hover effect
                        }
                    }}
                >
                    <FaDiscord style={{ marginRight: '10px', width: '25px', height: '25px' }} />
                    Join Discord
                </Button>
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', padding: 2, borderRadius: '8px' }}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: .2, marginTop: 1 }}>
                    Explore Stats
                </Typography>
                <Typography variant="body1" color="white" paragraph>
                    Check out the stats for NAEHL!
                </Typography>
                <Button variant="contained" color="primary" href="/NAEHL/standings">
                    Standings
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/skater-leaderboard" sx={{ marginLeft: 2 }}>
                    Skater Leaderboard
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/goalie-leaderboard" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard
                </Button>
                {/* <Button variant="contained" color="primary" href="/NAEHL/skater-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Skater Leaderboard Playoffs
                </Button>
                <Button variant="contained" color="primary" href="/NAEHL/goalie-leaderboard-playoffs" sx={{ marginLeft: windowWidth < 600 ? 0 : windowWidth < 900 ? 2 : 2, marginTop: windowWidth < 600 ? 1 : windowWidth < 900 ? 0 : 0 }}>
                    Goalie Leaderboard Playoffs
                </Button> */}
            </Box>

            <Box my={4} sx={{ backgroundColor: '#1f1f1f', borderRadius: '8px' }} style={BoxStyle}>
                <Typography variant="h6" color="white" fontWeight="800" gutterBottom sx={{ marginLeft: 2, marginTop: 2 }}>
                    Explore Teams
                </Typography>
                <Typography variant="body1" color="white" paragraph sx={{ marginLeft: 2 }}>
                    Check out the teams!
                </Typography>
                <Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} style={ContainerStyle}>
                    <Container sx={{ display: 'flex', flexDirection: 'column' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'adler-mannheim'} text={'Adler Mannheim'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'dynamo-pardubice'} text={'Dynamo Pardubice'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'farjestad-bk'} text={'Farjestad BK'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'geneve-servette-hc'} text={'Geneve Servette HC'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'grizzlys-wolfsburg'} text={'Grizzlys Wolfsburg'} league={'NAEHL'} />
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} style={ContainerStyle}>
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'innsbruck-die-haie'} text={'Innsbruck Die Haie'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'lahti-pelicans'} text={'Lahti Pelicans'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'lulea-hf'} text={'Lulea HF'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'redbull-munchen'} text={'Redbull Munchen'} league={'NAEHL'} />
                        <Divider sx={{ borderColor: '#424549' }} /><LogoButtonWithIcon team={'tappara-tampere'} text={'Tappara Tampere'} league={'NAEHL'} />

                    </Container>
                </Container>
            </Box>
        </Container>
    );
}



export default NAEHL_Home;