import React, { useEffect, useState, useMemo } from 'react';
import { useLoading } from '../Contexts/LoadingContext';
import Autocomplete from '@mui/lab/Autocomplete';
import { PropagateLoader } from 'react-spinners';
import { Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, TablePagination, TextField } from '@mui/material';

function GoalieLeaderboardSixes({ league }) {

    useEffect(() => {
        document.title = `Chel stats | ${league.toUpperCase()} Goalie Leaderboard`;
    }, [league]);

    const [sheetData, setSheetData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const { loading, setLoading } = useLoading();
    const [sortConfig, setSortConfig] = useState({
        key: 'Wins',
        direction: 'desc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [usernameFilter, setUsernameFilter] = useState(null);

    const usernameOptions = useMemo(() => {
        const usernames = new Set(sheetData.map(row => row[0])); // First column is 'Goalies'
        return Array.from(usernames);
    }, [sheetData]);

    const positionAcronyms = {
        'goalie': 'G',
        'Defenseman': 'D',
        'Forward': 'F',
    };

    const visibleColumns = [
        { key: 'rank', label: 'Rank', sortable: false },
        { key: 'Username', sortable: true },
        { key: 'Platform', sortable: true },
        { key: 'Position', sortable: true },
        { key: 'Games Played', sortable: true },
        { key: 'Record', sortable: true },
        { key: 'Goals Against', sortable: true },
        { key: 'GAA', sortable: true },
        { key: 'Saves', sortable: true },
        { key: 'Shots Against', sortable: true },
        { key: 'Save %', sortable: true },
        { key: 'Breakaway Saves', sortable: true },
        { key: 'Breakaway Save %', sortable: true },
        { key: 'Penalty Shot Saves', sortable: true },
        { key: 'Penalty Shot Save %', sortable: true },
        { key: 'Diving Saves', sortable: true },
        { key: 'Pokechecks', sortable: true },
        { key: 'PK Zone Clears', sortable: true },
        { key: 'Shutout Periods', sortable: true },
        { key: 'Shutouts', sortable: true },
        { key: 'GSAA', sortable: true },
        { key: 'GWAR', sortable: true }
    ];

    useEffect(() => {
        setLoading(true);
        const fetchSheetData = async () => {
            try {
                const response = await fetch(`/api/fetch/leaderboard?league=${league}&type=goalie`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                let data = await response.json();
                setHeaders(data.values[0]);
                data = data.values.slice(1); // Skip the header row

                // Filter out rows with bad data
                data = data.filter(row => {
                    const username = row[0];
                    const otherValues = row.slice(1);

                    // Check if the row has valid data
                    const hasValidData = username && username !== "0" &&
                        otherValues.some(value => value && value !== "0" && value !== "#DIV/0!");

                    return hasValidData;
                });

                setSheetData(data); // Store the filtered data
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch sheet data:', error);
                setLoading(false);
            }
        };

        fetchSheetData();
    }, [league]);

    const handleSort = (key) => {
        const isAsc = sortConfig.key === key && sortConfig.direction === 'asc';
        setSortConfig({ key, direction: isAsc ? 'desc' : 'asc' });
    };

    const filteredData = useMemo(() => {
        if (!usernameFilter) return sheetData; // No filtering
        return sheetData.filter(row => row[0] === usernameFilter); // Filter by the 'Goalies' column
    }, [sheetData, usernameFilter]);

    const sortedData = useMemo(() => {
        if (filteredData.length > 0 && headers.includes(sortConfig.key)) {
            const columnIndex = headers.indexOf(sortConfig.key);
            return filteredData.sort((a, b) => {
                const aValue = isNaN(+a[columnIndex]) ? a[columnIndex] : +a[columnIndex];
                const bValue = isNaN(+b[columnIndex]) ? b[columnIndex] : +b[columnIndex];

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return filteredData; // Return filteredData directly if not sorting
    }, [filteredData, sortConfig, headers]);

    if (loading) {
        return <PropagateLoader style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }} color="#3498db" />;
    }

    if (!sheetData.length) return null;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };

    return (
        <Box sx={{ maxWidth: '95%', m: 'auto', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ m: 'auto', marginTop: 4, color: 'white' }}>Goalie Leaderboard</Typography>
            <Autocomplete
                options={usernameOptions}
                getOptionLabel={(option) => option ? option.toString() : ''}
                value={usernameFilter}
                onChange={(event, newValue) => {
                    setUsernameFilter(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Filter by Username" variant="outlined" />}
                sx={{
                    marginTop: 2, marginBottom: 0.5, width: 300, input: { color: 'white' },
                    label: { color: 'gray' },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'gray' },
                        '&:hover fieldset': { borderColor: 'white' },
                        '&.Mui-focused fieldset': { borderColor: 'gray' },
                    },
                    '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
                }}
                freeSolo
            />
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1C4587' }}>
                            {visibleColumns.map((column, columnIndex) => {
                                const { key, sortable } = column;
                                return (
                                    <TableCell
                                        key={key}
                                        sx={{
                                            backgroundColor: '#1C4587',
                                            color: 'white',
                                            p: '2px',
                                            paddingLeft: '6px',
                                            textAlign: 'center',
                                            whiteSpace: 'nowrap',
                                            borderRight: columnIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                            position: columnIndex === 1 ? 'sticky' : undefined,
                                            left: columnIndex === 1 ? 0 : undefined,
                                            zIndex: columnIndex === 1 ? 2 : 1,
                                        }}
                                    >
                                        {sortable ? (
                                            <TableSortLabel
                                                active={sortConfig.key === key}
                                                direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
                                                onClick={() => handleSort(key)}
                                            >
                                                {key.toUpperCase()}
                                            </TableSortLabel>
                                        ) : key.toUpperCase()}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                            <TableRow key={rowIndex} sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                {visibleColumns.map((column, colIndex) => {
                                    let cellValue;

                                    if (column.key === 'rank') {
                                        cellValue = rowIndex + 1 + page * rowsPerPage; // Adding the rank for each row
                                    } else if (column.key === 'Position') {
                                        cellValue = positionAcronyms[row[headers.indexOf(column.key)]] || row[headers.indexOf(column.key)];
                                    } else {
                                        cellValue = row[headers.indexOf(column.key)] || '0';
                                    }

                                    return (
                                        <TableCell
                                            key={colIndex}
                                            sx={{
                                                textAlign: 'left',
                                                whiteSpace: 'nowrap',
                                                width: '50px',
                                                fontWeight: '500',
                                                borderRight: colIndex === visibleColumns.length - 1 ? 'none' : '1px solid rgba(224, 224, 224, 1)',
                                                position: colIndex === 1 ? 'sticky' : undefined,
                                                left: colIndex === 1 ? 0 : undefined,
                                                backgroundColor: colIndex === 1 ? 'white' : undefined,
                                                zIndex: 1,
                                            }}
                                        >
                                            {cellValue}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
            <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', width: '100%', zIndex: 1 }}>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    component="div"
                    count={sortedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{zIndex: 5}}
                />
            </Box>
        </Box>
    );
}

export default GoalieLeaderboardSixes;
