import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';

const SearchAndDropdown = ({ username, setUsername, teamname, setTeamName, refreshKey, setRefreshKey }) => {
  const [error, setError] = useState(''); // Error state for validation
  const navigate = useNavigate();
  const location = useLocation();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    if (event.target.value) {
      setError(''); // Clear error when user starts typing
    }
  };

  const handleTeamNameChange = (event) => setTeamName(event.target.value);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setUsername(queryParams.get('username') || '');
    setTeamName(queryParams.get('teamname') || '');
  }, [location.search, setUsername, setTeamName, refreshKey]);

  const handleButtonClick = () => {
    if (!username.trim()) {
      setError('Gamertag is required'); // Set error if username is empty
      return;
    }

    let targetUrl = `/players?username=${username.trim()}&console=common-gen5`;
    if (teamname) {
      targetUrl += `&teamname=${teamname.trim()}`;
    } else {
      targetUrl += `&freeAgent=True`;
    }

    const fullUrl = window.location.origin + targetUrl;

    if (window.location.href === fullUrl) {
      window.location.reload();
    } else {
      window.location.replace(fullUrl);
    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexWrap: windowWidth < 600 ? 'wrap' : windowWidth < 900 ? 'nowrap' : 'nowrap', gap: 1, alignItems: 'center', margin: 'auto', marginTop: 3 }}>
      
      <TextField
        label={error ? error : "Gamertag"} // Show error message as label if there is an error
        variant="outlined"
        value={username}
        onInput={handleUsernameChange}
        error={!!error} // Set error state if there's an error
        sx={{
          width: '48.5%',
          input: {
            color: 'white',
            '&:-webkit-autofill': {
              WebkitTextFillColor: 'white !important',
              transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
              WebkitBoxShadow: '0 0 0px 1000px transparent inset',
            }
          },
          label: { color: error ? 'red' : 'gray' }, // Change label color to red if error exists
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: error ? 'red' : 'gray' }, // Red border on error
            '&:hover fieldset': { borderColor: error ? 'red' : 'white' },
            '&.Mui-focused fieldset': { borderColor: error ? 'red' : 'white' },
          },
          '& .MuiInputLabel-root.Mui-focused': { color: error ? 'red' : 'white' } // Change label color when focused
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setUsername('')}
                edge="end"
                sx={{ color: 'white' }}
              >
                {username ? <ClearIcon /> : null}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Club name (optional)"
        variant="outlined"
        value={teamname}
        onInput={handleTeamNameChange}
        sx={{
          width: '48.5%',
          input: {
            color: 'white',
            '&:-webkit-autofill': {
              WebkitTextFillColor: 'white !important',
              transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
              WebkitBoxShadow: '0 0 0px 1000px transparent inset',
            }
          },
          label: { color: 'gray' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: 'gray' },
            '&:hover fieldset': { borderColor: 'white' },
            '&.Mui-focused fieldset': { borderColor: 'white' },
          },
          '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setTeamName('')}
                edge="end"
                sx={{ color: 'white' }}
              >
                {teamname ? <ClearIcon /> : null}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button
        sx={{ width: windowWidth < 600 ? '100%' : windowWidth < 900 ? '20%' : '20%', height: windowWidth < 600 ? 'unset' : windowWidth < 900 ? '50px' : '50px' }}
        variant="contained"
        color="primary"
        onClick={handleButtonClick}
      >
        Search
      </Button>
    </Box>
  );
};

export default SearchAndDropdown;
