import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Tab, Tabs, Typography, Card, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Paper, Grid, Divider } from '@mui/material';
import OverallRatingBar from '../EASHL/Players/OverallRatingBar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RemoveIcon from '@mui/icons-material/Remove';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{ height: '100%', overflow: 'auto', minHeight: windowWidth < 600 ? '200px' : windowWidth < 900 ? '100%' : '100%' }}
        >
            {value === index && (
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const StatsIndicator = ({ recent, career }) => {
    const validRecent = isFinite(recent) ? recent : 0;
    const validCareer = isFinite(career) ? career : 0;

    const goodPerformance = validRecent > validCareer;
    const badPerformance = validRecent < validCareer;
    const neutralPerformance = validRecent === validCareer;

    return (
        <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
            {goodPerformance ? (
                <ArrowUpwardIcon sx={{ color: 'green', fontSize: '22px' }} />
            ) : badPerformance ? (
                <ArrowDownwardIcon sx={{ color: 'red', fontSize: '22px' }} />
            ) : (
                <RemoveIcon sx={{ color: 'yellow', fontSize: '22px' }} />
            )}
        </Typography>
    );
};
const displayStat = (label, stat) => {
    const excludeList = ['Possession per Game'];
    const percList = ['Breakaway Save %', 'Penalty Shot Save %"'];

    if (excludeList.includes(label)) {
        return stat.substring(3, 8);
    } else if (stat == '-') {
        return '-';
    } else if (percList.includes(label)) {
        return stat.replace('%', '');
    } else if (label === 'Dekes per Game') {
        return stat;
    } else if (!isFinite(stat)) {
        return 0;
    } else {
        return stat;
    }
};

const displayRecentStat = (label, stat) => {
    const excludeList = ['Possession per Game'];
    if (excludeList.includes(label)) {
        return stat.substring(3, 8);
    } else if (stat == '-') {
        return '-';
    } else if (label === 'Dekes per Game') {
        return stat;
    } else if (label.includes('%')) {
        return '-';
    } else if (!isFinite(stat)) {
        return 0;
    } else {
        return stat;
    }
};

const createData = (name, total, per_game) => {
    return { name, total, per_game };
};

const HockeyDataComponent = ({ playerStats }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '80vh', // Set the max height for the modal
        overflowY: 'auto' // Enable vertical scrolling
    };
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const OffensiveStats = [
        createData('Offense rating', playerStats['Offense rating'], '-'),
        createData('Goals', playerStats.Goals, playerStats['Goals per Game']),
        createData('Assists', playerStats.Assists, playerStats['Assists per Game']),
        createData('Points', playerStats.Points, playerStats['Points per Game']),
        createData('+/-', playerStats['(+/-)'], playerStats['(+/-) per Game']),
        createData('Powerplay goals', playerStats['Powerplay goals'], (parseInt(playerStats['Powerplay goals'])/parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Shorthanded goals', playerStats['Shorthanded goals'], (parseInt(playerStats['Shorthanded goals'])/parseInt(playerStats['Games Played'])).toFixed(2)),
    ];

    const Shooting = [
        createData('Shots', playerStats.Shots, playerStats['Shots per Game']),
        createData('Shot attempts', playerStats['Shot attempts'], playerStats['Shot attempts per game']),
        createData('Missed shots', playerStats['Missed shots'], playerStats['Missed shots per game']),
        createData('Shot %', playerStats['ShotPerc'], '-'),
        createData('Shots on net %', playerStats['Shots on net Perc'], '-'),
    ];

    const Passing = [
        createData('Assists', playerStats.Assists, playerStats['Assists per Game']),
        createData('Passes', playerStats.Passes, playerStats['Passes per Game']),
        createData('Pass attempts', playerStats['Pass attempts'], playerStats['Pass attempts per game']),
        createData('Pass %', playerStats['PassPerc'], '-'),
        createData('Saucer passes', playerStats['Saucer passes'], playerStats['Saucer passes per Game']),
        createData('Giveaways', playerStats.Giveaways, playerStats['Giveaways per Game']),
    ];

    const Handeye = [
        createData('Faceoff %', playerStats['FaceoffPerc'], '-'),
        createData('Faceoff wins', playerStats['Faceoff wins'], (parseInt(playerStats['Faceoff wins']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Faceoff losses', playerStats['Faceoff losses'], (parseInt(playerStats['Faceoff losses']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Possession time', '-', playerStats['Possession (Min Per Game)']),
        createData('Deflections', playerStats.Deflections, playerStats['Deflections per Game']),
    ];

    const Physicality = [
        createData('Teamplay', playerStats['Teamplay rating'], '-'),
        createData('Hits', playerStats.Hits, playerStats['Hits per Game']),
        createData('Penalties drawn', playerStats['Penalties drawn'], playerStats['Penalties drawn per Game']),
        createData('PIM', playerStats['PIM'], playerStats['PIM per Game']),
    ];

    const DefensiveStats = [
        createData('Defense rating', playerStats['Defense rating'], '-'),
        createData('Hits', playerStats.Hits, playerStats['Hits per Game']),
        createData('Interceptions', playerStats.Interceptions, playerStats['Interceptions per Game']),
        createData('Blocked shots', playerStats['Blocked shots'], playerStats['Blocked shots per Game']),
        createData('Takeaways', playerStats.Takeaways, playerStats['Takeaways per Game']),
        createData('PK zone clears', playerStats['PK zone clears'], (parseInt(playerStats['PK zone clears']) / parseInt(playerStats['Games Played'])).toFixed(1)),
    ];

    const AdvancedStats = [
        createData('WAR %', playerStats.WAR ? playerStats.WAR.replace('%', '') : '-', '-'),
        createData('EVO', playerStats.EVO, '-'),
        createData('GAR', playerStats.GAR, '-'),
        createData('Sht% at EV', playerStats['Sht% at EV'] ? playerStats['Sht% at EV'].replace('%', '') : '-', '-'),

    ];

    const GoalieStats = [
        createData('Saves', playerStats['Saves'], (parseInt(playerStats['Saves']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Shots against', playerStats['Shots Against'], (parseInt(playerStats['Shots Against']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Pokechecks', playerStats['Pokechecks'], (parseInt(playerStats['Pokechecks']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Diving saves', playerStats['Diving Saves'], (parseInt(playerStats['Diving Saves']) / parseInt(playerStats['Games Played'])).toFixed(2)),
    ];

    const GoalieStats2 = [
        createData('Breakaway Saves', playerStats['Breakaways Saves'], (parseInt(playerStats['Breakaways Saves']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Breakaway Save %', playerStats['Breakaway Save %'], '-'),
        createData('Penalty Shot Saves', playerStats['Penalty Shot Saves'], (parseInt(playerStats['Penalty Shot Saves']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Penalty Shot Save %', playerStats['Penalty Shot Save %'], '-'),
        createData('Shutouts', playerStats['Shutouts'], (parseInt(playerStats['Shutouts']) / parseInt(playerStats['Games Played'])).toFixed(2)),
        createData('Shutout periods', playerStats['Shutout Periods'], (parseInt(playerStats['Shutout Periods']) / parseInt(playerStats['Games Played'])).toFixed(2)),
    ];

    const GoalieStats3 = [
        createData('Goals allowed', playerStats['Goals Against'], playerStats.GAA),
        createData('Save %', playerStats['Save %'], '-'),
    ];

    const GoalieAdvancedStats = [
        createData('GWAR', playerStats.GWAR ? playerStats.GWAR.replace('%', '') : '-', '-'),
        createData('GSAA', playerStats.GSAA, '-'),
    ];

    const overallRating = playerStats['Overall rating']?.['Overall Rating'] || 0;
    const shootingRating = playerStats['Overall rating']?.['Category Ratings']?.['SHT'] || 0;
    const passingRating = playerStats['Overall rating']?.['Category Ratings']?.['PSS'] || 0;
    const handeyeRating = playerStats['Overall rating']?.['Category Ratings']?.['HND'] || 0;
    const physicalityRating = playerStats['Overall rating']?.['Category Ratings']?.['PHS'] || 0;
    const defensiveRating = playerStats['Overall rating']?.['Category Ratings']?.['DEF'] || 0;

    const reflexRating = playerStats['Overall rating']?.['Category Ratings']?.['REF'] || 0;
    const consistencyRating = playerStats['Overall rating']?.['Category Ratings']?.['CON'] || 0;
    const clutchRating = playerStats['Overall rating']?.['Category Ratings']?.['CLU'] || 0;

    if (['C', 'LW', 'RW', 'D'].includes(playerStats['Position'])) {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Career Stats" sx={{ color: 'white' }} />
                        <Tab label="Shooting" sx={{ color: 'white' }} />
                        <Tab label="Passing" sx={{ color: 'white' }} />
                        <Tab label="Handeye" sx={{ color: 'white' }} />
                        <Tab label="Physicality" sx={{ color: 'white' }} />
                        <Tab label="Defense" sx={{ color: 'white' }} />
                        <Tab label="Advanced" sx={{ color: 'white' }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6} >
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Offensive Totals</Typography>
                                <Typography sx={{ color: 'white' }}>Games played: {playerStats['Games Played']}</Typography>
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(overallRating)} category={'OVR'} />
                                <TableContainer component={Paper} sx={{ marginTop: 5, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {OffensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                        <Divider sx={{ borderColor: '#424549', marginTop: 3 }} />
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 2, mt: 3 }}>Defensive Totals</Typography>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Shooting</Typography>
                                <OverallRatingBar rating={parseInt(shootingRating)} category={'SHT'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Shooting.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Passing</Typography>
                                <OverallRatingBar rating={parseInt(passingRating)} category={'PSS'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Passing.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Handeye</Typography>
                                <OverallRatingBar rating={parseInt(handeyeRating)} category={'HND'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Handeye.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Physicality</Typography>
                                <OverallRatingBar rating={parseInt(physicalityRating)} category={'PHS'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Physicality.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={5}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Defense</Typography>
                                <OverallRatingBar rating={parseInt(defensiveRating)} category={'DEF'} />
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {DefensiveStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={6}>
                    <Button onClick={handleOpen}>Stats Glossary</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Stats Glossary
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <b>Sht% at EV</b> (Shooting% for your player at Even Strength)<br></br><br></br>
                                <b>WAR</b> (Individualized % that says what chance your team wins with you on the ice. (80% and up is considered Elite))<br></br><br></br>
                                <b>EVO</b> (Even Strength Offence (goals for) Generated while you are on the ice (higher is good))<br></br><br></br>
                                <b>GAR</b> (Goals Above Replacement (Higher is good))<br></br><br></br>
                            </Typography>
                            <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button> {/* Button to close the modal */}
                        </Box>
                    </Modal>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Advanced Stats</Typography>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {AdvancedStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    } else {
        return (
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="Hockey stats tabs" variant="scrollable">
                        <Tab label="Career Stats" sx={{ color: 'white' }} />
                        <Tab label="Advanced" sx={{ color: 'white' }} />
                    </Tabs>
                </Box>
                <TabPanel value={tabIndex} index={0}>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Goalie stats</Typography>
                                <Typography sx={{ color: 'white' }}>Games played: {playerStats['Goalie games played']}</Typography>
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(overallRating)} category={'OVR'} />
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(consistencyRating)} category={'CON'} />
                                <TableContainer component={Paper} sx={{ marginTop: 2, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats3.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(clutchRating)} category={'CLU'} />
                                <TableContainer component={Paper} sx={{ marginTop: 2, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats2.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Box sx={{ marginTop: 3 }}></Box>
                                <OverallRatingBar rating={parseInt(reflexRating)} category={'REF'} />
                                <TableContainer component={Paper} sx={{ marginTop: 2, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                <Button onClick={handleOpen}>Stats Glossary</Button>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Stats Glossary
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <b>GWAR</b> (Goalie WAR% Individualized % that says what chance your team has of winning with you in net)<br></br><br></br>
                                <b>GSAA</b> (Goalie Save Percentage Above Replacement)<br></br><br></br>
                            </Typography>
                            <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button> {/* Button to close the modal */}
                        </Box>
                    </Modal>
                    <Grid sx={{ maxWidth: '100%', marginTop: 3, display: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'flex' : 'flex', flexDirection: windowWidth < 600 ? 'unset' : windowWidth < 900 ? 'column' : 'column', flexBasis: '100%', justifyContent: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Card sx={{ backgroundColor: 'transparent' }}>
                                <Typography variant="h6" sx={{ color: 'white', marginBottom: 1 }}>Advanced Stats</Typography>
                                <TableContainer component={Paper} sx={{ marginTop: 3, backgroundColor: '#171717', borderRadius: 5, padding: 1 }}>
                                    <Table sx={{ minWidth: 100 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white' }}>Stat</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Total</TableCell>
                                                <TableCell sx={{ color: 'white' }} align="right">Per game</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {GoalieAdvancedStats.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell sx={{ color: 'white' }} component="th" scope="row">{row.name}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayStat(row.name, row.total)}</TableCell>
                                                    <TableCell sx={{ color: 'white' }} align="right">{displayRecentStat(row.name, row.per_game)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        );
    }
};

export default HockeyDataComponent;
