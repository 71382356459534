import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useHref } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink, styled } from '@mui/material';
import { AppBar, Toolbar, IconButton, Typography, List, ListItem, ListItemIcon, ListItemText, CssBaseline, Box, ThemeProvider, createTheme, Collapse, Breadcrumbs, Divider, Avatar, Drawer, SwipeableDrawer, Tabs, Tab } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import DomainIcon from '@mui/icons-material/Domain';
import HubIcon from '@mui/icons-material/Hub';
import SportsHockeyIcon from '@mui/icons-material/SportsHockey';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { StatsProvider } from './Contexts/StatsContext';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import HomePage from './Main Components/HomePage';
import PlayerStats from './EASHL/Players/PlayerStats';
import ClubStats from './EASHL/Clubs/ClubStats';
import RecentGame from './EASHL/Clubs/RecentGame'
import { refreshUserData } from './EASHL/Players/PlayerStats';
import AboutPage from './Main Components/AboutPage';
import Footer from './Main Components/Footer';
import myBanner from './Main Components/Logos/image.png';
import AuthButton from './Main Components/AuthButton';
import { UserProvider, useUser } from './Contexts/UserContext';
import { LoadingProvider } from './Contexts/LoadingContext';
import StandingsPage from './Leagues/StandingsPage';
import LeaguesTeamPageThrees from './Leagues/LeaguesTeamPageThrees';
import LeaguesTeamPageSixes from './Leagues/LeaguesTeamPageSixes';
import PGL_Home from './Leagues/PGL/PGL_Home';
import SkaterLeaderboardThrees from './Leagues/SkaterLeaderboardThrees';
import GoalieLeaderboardThrees from './Leagues/GoalieLeaderboardThrees';
import SkaterLeaderboardSixesPlayoffs from './Leagues/SkaterLeaderboardSixesPlayoffs';
import GoalieLeaderboardSixesPlayoffs from './Leagues/GoalieLeaderboardSixesPlayoffs';
import GoalieLeaderboardSixes from './Leagues/GoalieLeaderboardSixes';
import EASHLDirectory from './Main Components/EASHLDirectory';
import queryString from 'query-string';
import FreeAgentsList from './Main Components/FreeAgentsList'
import ScoutingClubsList from './Main Components/ScoutingClubsList'
import StatsInfo from './Main Components/StatsInfo'
import Redirect from './Main Components/Redirect'
import ChangeLog from './Main Components/ChangeLog'
import NAEHL_Home from './Leagues/NAEHL/NAEHL_Home'
// import HockeyCards from './EASHL/Players/HockeyCards';
import PlayerProfile from './Leagues/PlayerProfile';
import SkaterLeaderboard from './Leagues/SkaterLeaderboard'
import WCA_Home from './Leagues/WCA/WCA_Home'
import NavBar from './Leagues/NavBar'
import leagues from './leagues';

// Theme creation
const theme = createTheme({
    palette: {
        background: {
            default: '#121212', // Use your desired color
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1f1f1f',
                    color: '#fff',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px #121212 inset', // Change #000 to match your desired background color
                        WebkitTextFillColor: 'white', // Adjust text color as needed
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontSize: '24px',
            fontWeight: 700, // Medium weight for h1
        }
        // Continue for other variants as needed
    },
});

function Navigation({ mobileOpen, handleDrawerToggle }) {
    const location = useLocation();
    const [statsOpen, setStatsOpen] = useState(false);
    const [playersOpen, setPlayersOpen] = useState(false);
    const [clubsOpen, setClubsOpen] = useState(false);
    const [agencyOpen, setAgencyOpen] = useState(false);
    const [freeAgentsOpen, setFreeAgentsOpen] = useState(false);
    const [scoutingOpen, setScoutingOpen] = useState(false);
    const [leaguesOpen, setLeaguesOpen] = useState(false);
    const [hubOpen, setHubOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = false;

    const handleStatsClick = () => { // New handler for Players collapse
        setStatsOpen(!statsOpen);
    };
    const handlePlayersClick = () => { // New handler for Players collapse
        setPlayersOpen(!playersOpen);
    };
    const handleClubsClick = () => { // New handler for Players collapse
        setClubsOpen(!clubsOpen);
    };
    const handleAgencyClick = () => { // New handler for Players collapse
        setAgencyOpen(!agencyOpen);
    };
    const handleHubClick = () => { // New handler for Players collapse
        setHubOpen(!hubOpen);
    };
    const handleLeaguesClick = () => { // New handler for Players collapse
        setLeaguesOpen(!leaguesOpen);
    };
    const handleInfoClick = () => { // New handler for Players collapse
        setInfoOpen(!infoOpen);
    };

    const handleItemClick = (event, path) => {
        // Close the drawer
        handleDrawerToggle();
        // Navigate if necessary (if using programmatic navigation)
        //history.push(path); // Uncomment if using useHistory for navigation
    };

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    };

    const DrawerComponent = isTouchDevice() ? SwipeableDrawer : Drawer;
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    return (
        <DrawerComponent
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            onOpen={handleDrawerToggle}
            disableBackdropTransition={!iOS} // Improve performance on iOS by disabling backdrop transition
            disableDiscovery={iOS} // Disable the swipe to open feature on iOS because it can interfere with navigation gestures
            swipeAreaWidth={15}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    width: 270,
                    backgroundColor: '#121212',
                    color: 'white', '&::-webkit-scrollbar': { display: 'none' }, // For WebKit browsers
                    scrollbarWidth: 'none', // For Firefox
                    '-ms-overflow-style': 'none'

                },
            }}
        >
            <Box sx={{ backgroundColor: '#121212' }}>
                <Box
                    sx={{
                        margin: 'auto',
                        width: 125,
                        height: 175,
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundSize: '100%', // or a specific percentage
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: `url(${myBanner})`,
                    }}
                />
            </Box>
            <List sx={{ padding: 0 }}>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button component={Link} to="/" key="home" sx={{ color: 'white', bgcolor: location.pathname === '/' ? 'primary.main' : 'inherit' }} onClick={(event) => handleItemClick(event, '/')}>
                    <ListItemIcon ><HomeIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Home" sx={{ color: 'white' }} />
                </ListItem>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleStatsClick}>
                    <ListItemIcon><CalculateIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="EASHL Stats" sx={{ color: 'white' }} />
                    {statsOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Divider sx={{ borderColor: '#424549' }} />
                <Collapse in={statsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/players/') ? 'primary.light' : 'inherit' }} component={Link} to="/players/" onClick={(event) => handleItemClick(event, '/players/')}>
                            <ListItemIcon>
                                <ArrowRightRoundedIcon sx={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Player search" />
                        </ListItem>
                    </List>
                    <Divider sx={{ borderColor: '#424549' }} />

                    <List component="div" disablePadding>
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/clubs/') ? 'primary.light' : 'inherit' }} component={Link} to="/clubs/" onClick={(event) => handleItemClick(event, '/clubs/')}>
                            <ListItemIcon>
                                <ArrowRightRoundedIcon sx={{ color: 'white' }} />
                            </ListItemIcon>
                            <ListItemText primary="Club search" />
                        </ListItem>
                    </List>
                </Collapse>
                {/* <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleAgencyClick}>
                    <ListItemIcon><DomainIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Agency" sx={{ color: 'white' }} />
                    {agencyOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={agencyOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/free-agents') ? 'primary.light' : 'inherit' }} component={Link} to="/free-agents" onClick={(event) => handleItemClick(event, '/free-agents')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Free Agents" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/scouting') ? 'primary.light' : 'inherit' }} component={Link} to="/scouting" onClick={(event) => handleItemClick(event, '/scouting')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Scouting" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse> */}
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleLeaguesClick}>
                    <ListItemIcon><SportsHockeyIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Leagues" sx={{ color: 'white' }} />
                    {leaguesOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={leaguesOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/league-directory') ? 'primary.light' : 'inherit' }} component={Link} to="/league-directory" onClick={(event) => handleItemClick(event, '/league-directory')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="League Directory" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/PGL') ? 'primary.light' : 'inherit' }} component={Link} to="/PGL" onClick={(event) => handleItemClick(event, '/PGL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="PGL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/NAEHL') ? 'primary.light' : 'inherit' }} component={Link} to="/NAEHL" onClick={(event) => handleItemClick(event, '/NAEHL')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="NAEHL" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/WCA') ? 'primary.light' : 'inherit' }} component={Link} to="/WCA" onClick={(event) => handleItemClick(event, '/WCA')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="WCA" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
                <ListItem button onClick={handleInfoClick}>
                    <ListItemIcon><InfoIcon sx={{ color: 'white' }} /></ListItemIcon>
                    <ListItemText primary="Info" sx={{ color: 'white' }} />
                    {infoOpen ? <ExpandLess sx={{ color: 'white' }} /> : <ExpandMore sx={{ color: 'white' }} />}
                </ListItem>
                <Collapse in={infoOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {/* <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/subscriptions') ? 'primary.light' : 'inherit' }} component={Link} to="/subscriptions" onClick={(event) => handleItemClick(event, '/subscriptions')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Subscriptions" sx={{ color: 'white' }} />
                        </ListItem> */}
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/about') ? 'primary.light' : 'inherit' }} component={Link} to="/about" onClick={(event) => handleItemClick(event, '/about')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="About" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/discord-server') ? 'primary.light' : 'inherit' }} component={Link} to="/discord-server" onClick={(event) => handleItemClick(event, '/discord-server')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Official Discord Server" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/change-log') ? 'primary.light' : 'inherit' }} component={Link} to="/change-log" onClick={(event) => handleItemClick(event, '/change-log')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Change Log" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/overall-ratings-info') ? 'primary.light' : 'inherit' }} component={Link} to="/overall-ratings-info" onClick={(event) => handleItemClick(event, '/overall-ratings-info')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Overall Ratings Info" sx={{ color: 'white' }} />
                        </ListItem>
                        <Divider sx={{ borderColor: '#424549' }} />
                        <ListItem button sx={{ color: 'white', pl: 4, bgcolor: location.pathname.startsWith('/privacy-policy') ? 'primary.light' : 'inherit' }} component={Link} to="/privacy-policy" onClick={(event) => handleItemClick(event, '/privacy-policy')}>
                            <ListItemIcon><ArrowRightRoundedIcon sx={{ color: 'white' }} /></ListItemIcon>
                            <ListItemText primary="Privacy Policy" sx={{ color: 'white' }} />
                        </ListItem>
                    </List>
                </Collapse>
                <Divider sx={{ borderColor: '#424549' }} />
            </List>

            <Box p={2} >
                <AuthButton></AuthButton>
            </Box>
        </DrawerComponent>
    );
}

const CustomLink = styled(MuiLink)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '12px',
    color: 'white !important',
    textDecoration: 'none',
    cursor: 'pointer',
    "&:visited, &:hover, &:active": {
        color: 'white !important',

    },
}));

function StyledRouterLink(props) {
    return <CustomLink component={RouterLink} {...props} />;
}

function filterParamsForPath(path, fullParams) {
    const params = queryString.parse(fullParams);
    const filteredParams = {};

    // Example logic for filtering parameters
    if (path.includes('/clubs')) {
        filteredParams['teamname'] = params['teamname']; // Assume 'id' is necessary for 'details'
        filteredParams['console'] = params['console'];
    }

    // Return the filtered parameters as a query string
    return queryString.stringify(filteredParams);
}

const BreadcrumbNav = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginLeft: '15px', marginTop: '5px', fontSize: '12px', color: 'white' }}>
            <StyledRouterLink to="/">home</StyledRouterLink>
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1;
                const pathToIndex = `/${pathnames.slice(0, index + 1).join('/')}`;
                const filteredSearch = filterParamsForPath(pathToIndex, location.search);
                const to = `${pathToIndex}${filteredSearch ? `?${filteredSearch}` : ''}`;

                return last ? (
                    <Typography color="textPrimary" key={to} sx={{ fontSize: '12px', color: 'white' }}>
                        {value}
                    </Typography>
                ) : (
                    <StyledRouterLink to={to} key={to}>
                        {value}
                    </StyledRouterLink>
                );
            })}
        </Breadcrumbs>
    );
};

function MyAppBar({ handleDrawerToggle }) {
    const { user, setUser } = useUser();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Handle menu open/close
    const handleOpenMenu = (event, label) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(label);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpenMenu(null);
    };

    // Fetch user data
    useEffect(() => {
        refreshUserData(setUser); // Assuming refreshUserData is a function in your context
    }, [setUser]);

    // Load the league logo with caching
    const loadLogo = (league) => {
        try {
            return require(`./Leagues/${league}/Logos/${league}_Logo.png`);
        } catch (error) {
            console.error("Error loading league logo:", error);
            return null;
        }
    };

    const isLeaguePage = location.pathname.startsWith('/NAEHL') || location.pathname.startsWith('/PGL') || location.pathname.startsWith('/WCA');

    const leagueData = useMemo(() => {
        if (location.pathname.startsWith('/NAEHL')) return leagues.NAEHL;
        if (location.pathname.startsWith('/PGL')) return leagues.PGL;
        if (location.pathname.startsWith('/WCA')) return leagues.WCA;
        return null;
    }, [location.pathname]);

    const leagueLogo = useMemo(() => leagueData ? loadLogo(leagueData.name) : null, [leagueData]);

    const getTeamLogoPath = (teamName) => {
        const formattedName = teamName.toLowerCase().replace(/\s+/g, '-');
        try {
            return require(`./Leagues/${leagueData.name}/Logos/${formattedName}.png`);
        } catch (error) {
            console.error("Error loading team logo:", error);
            return null;
        }
    };

    // Extract user details
    const username = user?.username || '';
    const profilePicURL = user
        ? `https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png?size=256`
        : '';

    const teamLogos = useMemo(() => {
        if (!leagueData || !leagueData.links) return {};

        const teamsLink = leagueData.links.find(link => link.label === "Teams");
        if (!teamsLink || !teamsLink.subLinks) return {};

        const logos = {};
        teamsLink.subLinks.forEach((team) => {
            const formattedName = team.label.toLowerCase().replace(/\s+/g, '-');
            try {
                logos[team.label] = require(`./Leagues/${leagueData.name}/Logos/${formattedName}.png`);
            } catch (error) {
                console.error(`Error loading logo for ${team.label}:`, error);
            }
        });
        return logos;
    }, [leagueData]);

    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#1f1f1f', paddingX: 1 }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 1 }}
                >
                    <MenuIcon />
                </IconButton>

                {/* League Logo and Title */}
                {isLeaguePage && leagueLogo ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <img src={leagueLogo} alt={`${leagueData.name} Logo`} style={{ height: 45, marginRight: 8 }} />
                        <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                            {leagueData?.name}
                        </Typography>
                    </Box>
                ) : (
                    <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold' }}>
                        Chel stats
                    </Typography>
                )}

                {/* Tabs for league-specific navigation */}
                <Box sx={{ flexGrow: 1 }}>
                    {leagueData && leagueData.links && (
                        <Tabs
                            value={location.pathname}
                            textColor="inherit"
                            indicatorColor="secondary"
                            sx={{
                                minHeight: 50,
                                '& .MuiTab-root': {
                                    color: 'white',
                                    fontWeight: 600,
                                    paddingX: 2,
                                    fontSize: '0.875rem',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    },
                                    '&.Mui-selected': {
                                        color: '#FFD700', // Change to your preferred active color
                                    },
                                },
                            }}
                        >
                            {leagueData.links.map((link, index) => (
                                link.subLinks ? (
                                    <Box key={index}>
                                        <Tab
                                            label={link.label}
                                            onClick={(event) => handleOpenMenu(event, link.label)}
                                            sx={{ color: 'white' }}
                                        />
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={openMenu === link.label}
                                            onClose={handleCloseMenu}
                                        >
                                            {link.subLinks.map((subLink, subIndex) => (
                                                <MenuItem
                                                    key={subIndex}
                                                    component={Link}
                                                    to={subLink.path}
                                                    onClick={handleCloseMenu}
                                                    sx={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                    {link.label === "Teams" && teamLogos[subLink.label] && (
                                                        <img
                                                            src={teamLogos[subLink.label]}
                                                            alt={`${subLink.label} Logo`}
                                                            style={{
                                                                height: 30,
                                                                width: 30,
                                                                marginRight: 8,
                                                                
                                                            }}
                                                        />
                                                    )}
                                                    {subLink.label}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                ) : (
                                    <Tab
                                        key={index}
                                        label={link.label}
                                        component={Link}
                                        to={link.path}
                                        value={link.path}
                                        sx={{ color: 'white' }}
                                    />
                                )
                            ))}
                        </Tabs>
                    )}
                </Box>

                {/* User profile section */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                        alt={username}
                        src={profilePicURL}
                        sx={{ width: 24, height: 24, marginRight: 0.5 }}
                    />
                    {/* <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>
                        {username}
                    </Typography> */}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

function App() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <LoadingProvider>
            <UserProvider>
                <StatsProvider>
                    <ThemeProvider theme={theme} >
                        <Router basename="/">
                            {/* <AdComponent/> */}
                            {/* <Router> */}
                            <Box display="flex" flexDirection="column" height="100vh" >
                                <CssBaseline />
                                <MyAppBar handleDrawerToggle={handleDrawerToggle} />
                                <Toolbar /> {/* This is to offset content below AppBar */}
                                <Box component="main" flex="1">
                                    <Navigation mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                                    <BreadcrumbNav />
                                    <Routes>
                                        <Route path="/" element={<HomePage />} />
                                        <Route path="/players/" element={<PlayerStats />} />
                                        {/* <Route path="/cards/" element={<HockeyCards />} /> */}
                                        <Route path="/clubs/" element={<ClubStats />} />
                                        <Route path="/clubs/recent-games" element={<RecentGame />} />
                                        <Route path="/league-directory" element={<EASHLDirectory />} />
                                        <Route path="/free-agents" element={<FreeAgentsList />} />
                                        <Route path="/scouting" element={<ScoutingClubsList />} />
                                        <Route path="/overall-ratings-info" element={<StatsInfo />} />
                                        <Route path="/privacy-policy" element={<Redirect to="https://drive.google.com/file/d/1D31ArV-DBI2sHGspoSsc-1mYMw3JZzW6/view?usp=drive_link" />} />
                                        <Route path="/discord-server" element={<Redirect to="https://discord.gg/GHx7Zvwk2P" />} />
                                        <Route path="/change-log" element={<ChangeLog />} />
                                        <Route path="/about" element={<AboutPage />} />
                                        <Route path="/NAEHL" element={<NAEHL_Home />} />
                                        <Route path="/NAEHL/:team/:username" element={<PlayerProfile league={'NAEHL'} />} />
                                        <Route path="/NAEHL/standings" element={<StandingsPage league={'naehl'} />} />
                                        <Route path="/NAEHL/skater-leaderboard" element={<SkaterLeaderboard league={'NAEHL'} />} />
                                        <Route path="/NAEHL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'NAEHL'} />} />
                                        {/* <Route path="/NAEHL/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'NAEHL'} />} />
<Route path="/NAEHL/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'NAEHL'} />} /> */}
                                        <Route path="/NAEHL/malmo-redhawks" element={<LeaguesTeamPageSixes team={'Malmo Redhawks'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/lahti-pelicans" element={<LeaguesTeamPageSixes team={'Lahti Pelicans'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/dynamo-pardubice" element={<LeaguesTeamPageSixes team={'Dynamo Pardubice'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/redbull-munchen" element={<LeaguesTeamPageSixes team={'Redbull Munchen'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/adler-mannheim" element={<LeaguesTeamPageSixes team={'Adler Mannheim'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/farjestad-bk" element={<LeaguesTeamPageSixes team={'Farjestad BK'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/geneve-servette-hc" element={<LeaguesTeamPageSixes team={'Geneve Servette HC'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/grizzlys-wolfsburg" element={<LeaguesTeamPageSixes team={'Grizzlys Wolfsburg'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/innsbruck-die-haie" element={<LeaguesTeamPageSixes team={'Innsbruck Die Haie'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/lulea-hf" element={<LeaguesTeamPageSixes team={'Lulea HF'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/skelleftea-aik" element={<LeaguesTeamPageSixes team={'Skelleftea AIK'} league={'NAEHL'} />} />
                                        <Route path="/NAEHL/tappara-tampere" element={<LeaguesTeamPageSixes team={'Tappara Tampere'} league={'NAEHL'} />} />

                                        <Route path="/PGL" element={<PGL_Home />} />
                                        <Route path="/PGL/:team/:username" element={<PlayerProfile league={'PGL'} />} />
                                        <Route path="/PGL/standings" element={<StandingsPage league={'pgl'} />} />
                                        <Route path="/PGL/skater-leaderboard" element={<SkaterLeaderboard league={'PGL'} />} />
                                        <Route path="/PGL/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'PGL'} />} />
                                        {/* <Route path="/PGL/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'PGL'} />} />
                                        <Route path="/PGL/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'PGL'} />} /> */}
                                        <Route path="/PGL/ice-storm" element={<LeaguesTeamPageSixes team={'Ice Storm'} league={'PGL'} />} />
                                        <Route path="/PGL/huskies" element={<LeaguesTeamPageSixes team={'Huskies'} league={'PGL'} />} />
                                        <Route path="/PGL/greyhounds" element={<LeaguesTeamPageSixes team={'Greyhounds'} league={'PGL'} />} />
                                        <Route path="/PGL/party-mix" element={<LeaguesTeamPageSixes team={'Party Mix'} league={'PGL'} />} />
                                        <Route path="/PGL/slapshot-society" element={<LeaguesTeamPageSixes team={'Slapshot Society'} league={'PGL'} />} />
                                        <Route path="/PGL/newport-nukes" element={<LeaguesTeamPageSixes team={'Newport Nukes'} league={'PGL'} />} />
                                        <Route path="/PGL/arctic-invaders" element={<LeaguesTeamPageSixes team={'Arctic Invaders'} league={'PGL'} />} />
                                        <Route path="/PGL/dolphins" element={<LeaguesTeamPageSixes team={'Dolphins'} league={'PGL'} />} />

                                        <Route path="/WCA" element={<WCA_Home />} />
                                        <Route path="/WCA/:team/:username" element={<PlayerProfile league={'WCA'} />} />
                                        <Route path="/WCA/standings" element={<StandingsPage league={'wca'} />} />
                                        <Route path="/WCA/skater-leaderboard" element={<SkaterLeaderboard league={'WCA'} />} />
                                        <Route path="/WCA/goalie-leaderboard" element={<GoalieLeaderboardSixes league={'WCA'} />} />
                                        {/* <Route path="/WCA/skater-leaderboard-playoffs" element={<SkaterLeaderboardSixesPlayoffs league={'WCA'} />} />
                                        <Route path="/WCA/goalie-leaderboard-playoffs" element={<GoalieLeaderboardSixesPlayoffs league={'WCA'} />} /> */}
                                        <Route path="/WCA/philadelphia-flyers" element={<LeaguesTeamPageSixes team={'Philadelphia Flyers'} league={'WCA'} />} />
                                        <Route path="/WCA/yotes" element={<LeaguesTeamPageSixes team={'Yotes'} league={'WCA'} />} />
                                        <Route path="/WCA/chicago-blackhawks" element={<LeaguesTeamPageSixes team={'Chicago Blackhawks'} league={'WCA'} />} />
                                        <Route path="/WCA/ottawa-senators" element={<LeaguesTeamPageSixes team={'Ottawa Senators'} league={'WCA'} />} />
                                        <Route path="/WCA/quebec-nordiques" element={<LeaguesTeamPageSixes team={'Quebec Nordiques'} league={'WCA'} />} />
                                        <Route path="/WCA/vancouver-canucks" element={<LeaguesTeamPageSixes team={'Vancouver Canucks'} league={'WCA'} />} />
                                        <Route path="/WCA/detroit-red-wings" element={<LeaguesTeamPageSixes team={'Detroit Redwings'} league={'WCA'} />} />
                                        <Route path="/WCA/florida-panthers" element={<LeaguesTeamPageSixes team={'Florida Panthers'} league={'WCA'} />} />

                                    </Routes>
                                </Box>
                                <Footer />
                            </Box>
                        </Router>
                    </ThemeProvider>
                </StatsProvider>
            </UserProvider>
        </LoadingProvider>
    );
}

export default App;
